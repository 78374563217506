<template>
    <div class="none">
        <img src="../assets/images/none.jpg" alt srcset />
        <p>{{value}}</p>
    </div>
</template>

<script>
    export default {
        name:"None",
        props:{
            value:{
                type:String,
                default:"暂无数据"
            }
        }
    }
</script>

<style lang="scss" scoped>
.none {
  width: 100%;
  box-sizing: border-box;
  padding: .5rem 0;
  img {
    width: 2.7rem;
    height: auto;
    margin: 0rem auto;
    display: block;
  }
  p{
      font-size: .24rem;
      color: #999999;
      text-align: center;
      margin: .1rem auto 0;
  }
}
</style>