<template>
  <div class="cart-page">
    <Head title="购物车" />
    <div class="cart-warrper">
      <None value="空空如也" v-if="list.length == 0 &&loaded" />
      <ul class="cart-list">
        <li v-for="(item,index) in list" :key="index">
          <div
            class="mint-checkbox-core"
            :class="{'active':item.selected}"
            @click="selectBooks(item,index)"
          ></div>
          <div class="pic">
            <img v-lazy="item.pic" alt />
          </div>
          <div class="info">
            <h5>{{ item.title }}</h5>
            <div class="info-price">
              <p>
                现价：
                <span>¥{{item.price}}</span>
              </p>
              <div class="change-price">
                <i class="cart-surper-sub" @click.stop="buyNumSub(item.id)"></i>
                <span class="cart-surper-num">{{item.num}}</span>
                <i class="cart-surper-add" @click.stop="buyNumAdd(item.id)"></i>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="cart-foot">
      <div>
        <h5>
          应付合计：
          <span>¥{{money}}</span>
        </h5>
        <p>含运费¥{{expenses}}</p>
      </div>
      <div class="cart-btn" @click="onBuy">立即结算</div>
    </div>
  </div>
</template>

<script>
import {
  reqGetCartInfo,
  reqAddCart,
  reqSetCart,
  reqDelCart
} from "@/network/api";
import None from "components/None";
export default {
  data() {
    return {
      loaded: false,
      userInfo: this.$store.getters.userInfo,
      list: [],
      money: 0,
      expenses: 0
    };
  },
  components: {
    None
  },
  created() {
    this.getCartInfo();
  },
  methods: {
    onBuy() {
      let ids = [];
      this.list.forEach(item => {
        if (item.selected) {
          ids.push(item.id);
        }
      });
      if(ids.length==0) return this.$toast({ message: "未选中要下单的商品" });
      ids = ids.join(",");
      reqSetCart({
        ids
      }).then(res => {
        const { code, msg, backdata } = res;
        this.$toast({ message: msg });
        if (code != 0) return;
        this.$router.push({
          name: "order",
          query: {
            index: 1
          }
        });
      });
    },
    selectBooks(item, index) {
      if (item.selected) {
        item.selected = false;
        this.money -= item.price * item.num;
      } else {
        item.selected = true;
        this.money += item.price * item.num;
      }
    },
    buyNumSub(id) {
      reqDelCart({
        id,
        num: 1
      }).then(res => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.getCartInfo();
      });
    },
    buyNumAdd(id) {
      reqAddCart({
        id,
        num: 1
      }).then(res => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.getCartInfo();
      });
    },
    getCartInfo() {
      reqGetCartInfo({
        user_id: this.userInfo.id
      }).then(res => {
        const { code, msg, backdata } = res;
        this.loaded = true;
        if (code != 0) return;
        this.list = backdata.cart_list.map(item => {
          item.selected = true;
          return item;
        });
        this.money = backdata.cart_money;
        this.expenses = backdata.expenses;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-warrper {
  padding-bottom: 1.2rem;
}
.mint-checkbox-core {
  display: inline-block;
  background-color: #fff;
  border-radius: 100%;
  border: 1px solid #ccc;
  position: relative;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 0.12rem;
}
.mint-checkbox-core::after {
  border: 2px solid transparent;
  border-left: 0;
  border-top: 0;
  content: " ";
  top: 3px;
  left: 7px;
  position: absolute;
  width: 4px;
  height: 8px;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.mint-checkbox-core.active {
  background-color: #64ada4;
  border-color: #64ada4;
}
.mint-checkbox-core.active::after {
  border-color: #fff;
  -webkit-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
}
.cart-list {
  margin-top: 0.2rem;
  li {
    box-sizing: border-box;
    padding: 0.4rem 0.3rem;
    display: flex;
    align-items: center;
    position: relative;
    &:not(:last-of-type)::after {
      content: "";
      height: 1px;
      left: 0.3rem;
      right: 0.3rem;
      bottom: 0;
      background-color: #eff0ef;
      transform: scaleY(0.5);
      position: absolute;
    }
    .pic {
      width: 1.7rem;
      height: 1.7rem;
      background: #ffffff;
      border: 0.01rem solid #e5e5e5;
      border-radius: 0.08rem;
      margin-right: 0.22rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .info {
      flex: 1;
      h5 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.26rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 0.39rem;
        
      }
      span {
          color: rgba(255, 16, 16, 1);
        }
      .info-price {
        margin-top: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .change-price {
        display: flex;
        align-items: center;
        i {
          width: 0.44rem;
          height: 0.44rem;
          cursor: pointer;
          background-size: 100% auto;
          display: inline-block;
          background-repeat: no-repeat;
        }
        .cart-surper-sub {
          background-image: url(../../assets/images/btn_jianhao.png);
        }
        .cart-surper-add {
          background-image: url(../../assets/images/btn_jiahao.png);
        }
        .cart-surper-num {
          font-size: 0.32rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 0.39rem;
          margin: 0 0.45rem;
        }
      }
    }
  }
}
.cart-foot {
  height: 1.2rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 750px;
  z-index: 888;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0.3rem;
  &::before {
    content: "";
    height: 1px;
    background: #eff0ef;
    position: absolute;
    top: 0;
    left: 0.3rem;
    right: 0.3rem;
    transform: scaleY(0.5);
  }
  .cart-btn {
    width: 3.34rem;
    height: 0.88rem;
    background: #64ada4;
    border-radius: 0.44rem;
    font-size: 0.32rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  h5 {
    font-size: 0.32rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 0.48rem;
    span {
      font-size: 0.32rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ff1010;
    }
  }
  p {
    font-size: 0.22rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 0.48rem;
  }
}
</style>